import { StaticImage } from "gatsby-plugin-image"
import React from "react"
import Layout from "../components/layout"
import Seo from "../components/seo"

const CodeOfConduct = () => {
    return (
        <Layout>
            <Seo title="Code of Conduct" />
            <div className="container py-3 py-lg-4">
                <div className="row g-0 mb-3">
                    <div className="col border-bottom border-1 border-dark">
                        <h1>Code of Conduct</h1>
                    </div>
                </div>

                <div className="row">
                    <div className="card mb-3 border-0">
                        <div className="row gx-3">
                            <div className="col-lg-6 d-flex justify-content-center ">
                                <StaticImage
                                    transformOptions={{ cropFocus: "attention" }}
                                    alt="Sales team"
                                    src="../images/other/documents1.jpg"
                                    aspectRatio={16 / 7}
                                    width={696}
                                    layout="fixed"
                                />
                            </div>
                            <div className="col-lg-6 ">
                                <div className="card-body h-100 gray-bg d-flex flex-column">
                                    <p>
                                        We are committed to carrying our business in a honest, ethical and sustainable way with a high
                                        degree of integrity. In order to promote the long-term interests of Delta Corp and its
                                        stakeholders, the company strives to maintain the highest legal and ethical standards in all
                                        its business practices.
                                    </p>
                                    <p>
                                        We approach all our clients, customers and service providers maintaining our Integrity and
                                        remain accountable and responsible for all our actions. This allows us to take deliver a high
                                        quality service to our customers.
                                    </p>
                                    <p>
                                        Our people are our assets and we care about the well-being of Delta Corp team members and their
                                        families and our Code of Conduct embraces teamwork.
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </Layout>
    )
}

export default CodeOfConduct
